import React from 'react';
import { IQsrOrder } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import { useRestaurantContext } from '@/contexts/restaurant';
import { getCurrencyWithAmount } from '@/common/utility';
import { Voucher } from '@/components/InvoiceTable/types';
import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
    currencySymbol: string;
    currencyCode: string;
}

export default function QsrOrderInfo({ order, currencyCode, currencySymbol }: IProps) {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;

    const items = [
        ...(order.paymentResponse?.vouchers || []).map((item: Voucher) => ({ ...item, type: 'Voucher' })),
        ...(order.paymentResponse?.loyalties || []).map((item: any) => ({ ...item, type: 'Loyalty' })),
    ];

    const tip = (order.paymentResponse?.paymentRecords || []).reduce(
        (acc, { tipAmount }) => acc + Number(tipAmount),
        0,
    );
    const totalAmount = items.reduce((acc, item) => acc + Number(item.amount), 0);
    const paid = order.total - totalAmount;

    return (
        <div className={styles.main}>
            {order.subTotalStr && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Subtotal')}</div>
                    <div className={styles.value}>
                        {getCurrencyWithAmount(order.subTotal, currencySymbol, currencyCode, true, priceRoundingMode)}
                    </div>
                </div>
            )}

            {tip > 0 && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Tip')}</div>
                    <div className={styles.value}>
                        {getCurrencyWithAmount(tip, currencySymbol, currencyCode, true, priceRoundingMode)}
                    </div>
                </div>
            )}
            {order.totalStr && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Total')}</div>
                    <div className={styles.value}>
                        {getCurrencyWithAmount(
                            order.total + tip,
                            currencySymbol,
                            currencyCode,
                            true,
                            priceRoundingMode,
                        )}
                    </div>
                </div>
            )}
            {items.map((item: any, index: number) => (
                <React.Fragment key={index}>
                    <div className={styles.row}>
                        <div className={styles.label}>{item.type}</div>
                        <div className={styles.value}>
                            {getCurrencyWithAmount(item.amount, currencySymbol, currencyCode, true, priceRoundingMode)}
                        </div>
                    </div>
                </React.Fragment>
            ))}

            {items.length > 0 && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Paid')}</div>
                    <div className={styles.value}>
                        {getCurrencyWithAmount(paid, currencySymbol, currencyCode, true, priceRoundingMode)}
                    </div>
                </div>
            )}
            {order.customerComment && (
                <div className={classNames(styles.row, styles.comment)}>
                    <div className={classNames(styles.label, styles.fullWidth)}>{t('Customer Comment')}</div>
                    <div className={styles.value}>{order.customerComment}</div>
                </div>
            )}
        </div>
    );
}
